import React, { ReactNode } from "react";
import Head from "next/head";
import { ErrorBoundary } from "react-error-boundary";
import MenuPrimary from "../MenuPrimary/MenuPrimary";
import { LoadPlantsDb } from "components/LoadPlantsDb/LoadPlantsDb";
import { Logo } from "components/Logo/Logo";
import { ErrorModal } from "components/ErrorModal/ErrorModal";
import { NextSeo } from "next-seo";

type Props = {
  children?: ReactNode;
  title?: string;
  menu?: any[];
};

const Layout = ({ children, title = "", menu }: Props) => (
  <div>
    <NextSeo
      title={title ? `${title} - Pollinator Pathmaker` : "Pollinator Pathmaker"}
    />
    <Head>
      <title>{title && `${title} - `}Pollinator Pathmaker</title>
      <meta charSet="utf-8" />
      <meta
        name="viewport"
        content="width=device-width, user-scalable=no, minimum-scale=1.0, maximum-scale=1.0"
      ></meta>
    </Head>
    <header>
      <MenuPrimary menu={menu} />
    </header>
    <ErrorBoundary FallbackComponent={ErrorModal}>
      <LoadPlantsDb />
      {children}
    </ErrorBoundary>
  </div>
);

export default Layout;
