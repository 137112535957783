import React, { useEffect, useState, useCallback } from "react";
import Link from "next/link";
import { mq } from "styles/styles";
import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";

type Props = {
  menu?: any[];
};

const MenuPrimary = ({ menu }: Props) => {
  const { locale, asPath } = useRouter();
  const [open, setOpen] = useState(false);

  const { t } = useTranslation("common");

  useEffect(() => {
    if (open) {
      setOpen(false);
    }
  }, [asPath, locale]);

  const getParentClasses = (uri: string) => {
    return asPath.split("/")[1] === uri
      ? "MenuPrimary-parent MenuPrimary-parent-active"
      : "MenuPrimary-parent";
  };

  const getChildrenClasses = (uri: string) => {
    return asPath.substring(1) === uri ? "MenuPrimary-child-active" : "";
  };

  const getGrChildrenClasses = (uri: string) => {
    return asPath.substring(1) === uri
      ? "MenuPrimary-grandchild MenuPrimary-grandchild-active"
      : "MenuPrimary-grandchild";
  };

  const removeFocus = () => {
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  };

  const keyDownHandler = useCallback((event: KeyboardEvent) => {
    if (event.keyCode == 27) {
      //27 is the code for escape
      removeFocus();
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", keyDownHandler);

    return () => document.removeEventListener("keydown", keyDownHandler);
  }, []);

  return (
    <>
      {menu && (
        <button
          className={`menuButton ${open && "menuButtonOpen"}`}
          onClick={() => setOpen((v) => !v)}
        >
          <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M32.4068 5.26155C31.6703 3.23078 30.9339 1.20001 29.1849 1.20001C27.4358 1.20001 26.6994 3.23078 25.9629 5.26155C25.3186 6.92309 24.6742 8.67694 23.5695 8.67694C22.4649 8.67694 21.8205 6.92309 21.1761 5.26155C20.4396 3.23078 19.7032 1.20001 17.9542 1.20001C16.2051 1.20001 15.4687 3.23078 14.7322 5.26155C14.0879 6.92309 13.4435 8.67694 12.3388 8.67694C11.2342 8.67694 10.5898 6.92309 9.9454 5.26155C9.30102 3.23078 8.47252 1.20001 6.81554 1.20001C5.0665 1.20001 4.33006 3.23078 3.59362 5.26155C2.94924 6.92309 2.30485 8.67694 1.2002 8.67694V9.60001C2.94924 9.60001 3.68567 7.56924 4.42211 5.53847C5.0665 3.87694 5.71088 2.12309 6.81554 2.12309C7.9202 2.12309 8.56458 3.87694 9.20896 5.53847C9.9454 7.47693 10.6818 9.60001 12.4309 9.60001C14.1799 9.60001 14.9164 7.56924 15.6528 5.53847C16.2972 3.87694 16.9416 2.12309 17.9542 2.12309C18.9668 2.12309 19.7032 3.87694 20.3476 5.53847C21.084 7.47693 21.8205 9.60001 23.5695 9.60001C25.3186 9.60001 26.055 7.56924 26.7914 5.53847C27.4358 3.87694 28.0802 2.12309 29.1849 2.12309C30.2895 2.12309 30.9339 3.87694 31.5783 5.53847C32.3147 7.47693 33.0512 9.60001 34.8002 9.60001V8.67694C33.6955 8.67694 33.0512 6.92309 32.4068 5.26155Z" />
            <path d="M32.3215 17.5816C31.5871 15.6432 30.8527 13.5201 29.1084 13.5201C27.3641 13.5201 26.6297 15.5508 25.8953 17.5816C25.2527 19.2432 24.61 20.997 23.5084 20.997C22.4068 20.997 21.7641 19.2432 21.1215 17.5816C20.3871 15.6432 19.6527 13.5201 17.9084 13.5201C16.1641 13.5201 15.4297 15.5508 14.6953 17.5816C14.0527 19.2432 13.41 20.997 12.3084 20.997C11.2068 20.997 10.5641 19.2432 9.92151 17.5816C9.18708 15.6432 8.45265 13.5201 6.70839 13.5201C4.96413 13.5201 4.2297 15.5508 3.49528 17.5816C2.94446 19.2432 2.30183 20.997 1.2002 20.997V21.9201C2.94446 21.9201 3.67888 19.8893 4.41331 17.8585C5.05593 16.197 5.69856 14.4432 6.8002 14.4432C7.90183 14.4432 8.54446 16.197 9.18708 17.8585C9.92151 19.797 10.6559 21.9201 12.4002 21.9201C14.1445 21.9201 14.8789 19.8893 15.6133 17.8585C16.2559 16.197 16.8986 14.4432 18.0002 14.4432C19.1018 14.4432 19.7445 16.197 20.3871 17.8585C21.1215 19.797 21.8559 21.9201 23.6002 21.9201C25.3445 21.9201 26.0789 19.8893 26.8133 17.8585C27.4559 16.197 28.0986 14.4432 29.2002 14.4432C30.3018 14.4432 30.9445 16.197 31.5871 17.8585C32.3215 19.797 33.0559 21.9201 34.8002 21.9201V20.997C33.6068 20.997 32.9641 19.2432 32.3215 17.5816Z" />
            <path d="M32.3283 29.3416C31.5958 27.4031 30.8634 25.28 29.1239 25.28C27.3844 25.28 26.652 27.3108 25.9195 29.3416C25.2787 31.0031 24.6378 32.757 23.5392 32.757C22.4405 32.757 21.7997 31.0031 21.1588 29.3416C20.4264 27.4031 19.6939 25.28 17.9544 25.28C16.2149 25.28 15.4825 27.3108 14.7501 29.3416C14.1092 31.0031 13.4683 32.757 12.3697 32.757C11.271 32.757 10.6302 31.0031 9.9893 29.3416C9.25687 27.4031 8.52445 25.28 6.78494 25.28C5.04543 25.28 4.313 27.3108 3.58058 29.3416C2.9397 31.0031 2.29883 32.757 1.2002 32.757V33.68C2.9397 33.68 3.67213 31.6493 4.40456 29.6185C5.13698 27.957 5.77785 26.2031 6.87649 26.2031C7.97513 26.2031 8.616 27.957 9.25687 29.6185C9.9893 31.557 10.7217 33.68 12.4612 33.68C14.2007 33.68 14.9332 31.6493 15.6656 29.6185C16.3065 27.957 16.9473 26.2031 18.046 26.2031C19.1446 26.2031 19.7855 27.957 20.4264 29.6185C21.1588 31.557 21.8912 33.68 23.6307 33.68C25.3702 33.68 26.1026 31.6493 26.8351 29.6185C27.4759 27.957 28.1168 26.2031 29.2155 26.2031C30.3141 26.2031 30.955 27.957 31.5958 29.6185C32.3283 31.557 33.0607 33.68 34.8002 33.68V32.757C33.61 32.8493 32.9691 31.0954 32.3283 29.3416Z" />
          </svg>
        </button>
      )}

      <nav aria-label="Primary">
        <ul className="MenuPrimary">
          <li className="MenuPrimary-family MenuPrimary-family-small-screen-only">
            <p className="MenuPrimary-parent">{t`Create`}</p>
            <ul className="MenuPrimary-children">
              <li className={getChildrenClasses(`pathmaker`)}>
                <Link href={`/pathmaker`}>
                  <a
                    onClick={() => setOpen((v) => !v)}
                  >{t`Create my Garden`}</a>
                </Link>
              </li>
            </ul>
          </li>
          {menu &&
            menu.map(({ title, uri, subpages }) => (
              <li className="MenuPrimary-family" key={uri} tabIndex={0}>
                <p className={getParentClasses(uri)}>{title}</p>
                {subpages && (
                  <ul className="MenuPrimary-children">
                    {subpages.map(({ title, uri, subpages }) => (
                      <li className={getChildrenClasses(uri)} key={uri}>
                        <Link href={`/${uri}`}>
                          <a onClick={removeFocus}>{title}</a>
                        </Link>
                        {subpages && (
                          <ul className="MenuPrimary-grandChildren">
                            {subpages.map(({ title, uri }) => (
                              <li
                                className={getGrChildrenClasses(uri)}
                                key={uri}
                              >
                                <Link href={`/${uri}`}>
                                  <a onClick={removeFocus}>{title}</a>
                                </Link>
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
        </ul>
        <div className="overlay" onClick={() => setOpen((v) => !v)}></div>
      </nav>

      <style jsx>{`
        .menuButton {
          position: fixed;
          top: 1rem;
          left: 1rem;
          width: 4.5rem;
          height: 4.5rem;
          padding: 1rem;
          box-sizing: border-box;
          z-index: 12;
          border: none;
          background: none;
          cursor: pointer;

          background-color: ${open ? "transparent" : "var(--white)"};
          border-radius: 50%;

          transition: background 0.25s;
        }

        @media ${mq.extralargeUp} {
          .menuButton {
            display: none;
          }
        }

        .menuButton svg {
          width: 100%;
          height: 100%;
          fill: var(--black);
          stroke: var(--black);

          transition: background-color 0s linear;
          transition-delay: 0.5s;
        }

        .menuButton.invert svg,
        .menuButton.menuButtonOpen svg {
          fill: var(--white);
          stroke: var(--white);
          background-color: transparent;
          transition: none;
        }

        .MenuPrimary,
        .MenuPrimary-children {
          padding: 0;
          margin: 0;
          list-style: none;
          position: relative;
        }

        .MenuPrimary {
          position: fixed;
          width: 80%;
          height: 100vh;
          overflow: scroll;
          z-index: 11;
          padding-bottom: var(--spacing);

          background-color: var(--purple);
          border-top: 8rem solid var(--purple);

          transition: transform 0.5s ease-in-out;
          transform: translateX(${open ? "0%" : "-100%"});
        }

        @media ${mq.extralargeUp} {
          .MenuPrimary {
            width: auto;
            height: 6rem;
            overflow: visible;
            margin-top: var(--spacing);
            margin-left: var(--spacing);
            background-color: transparent;
            border-top: none;
            transform: none;
          }
        }

        .MenuPrimary-family {
          padding-bottom: var(--spacing-2x);
        }

        .MenuPrimary-family:last-of-type {
          padding-bottom: 12rem;
        }

        @media ${mq.extralargeUp} {
          .MenuPrimary-family,
          .MenuPrimary-family:last-of-type {
            padding-bottom: 0;
          }
        }

        .MenuPrimary-parent {
          display: block;
          padding: 0 1.5rem;
          margin: 0;
          color: var(--white);
          font-size: 1.75rem;
          font-weight: bold;
          line-height: 6rem;
          text-decoration: none;
          text-transform: uppercase;
        }

        @media ${mq.extralargeUp} {
          .MenuPrimary-parent {
            color: var(--black);
          }

          .MenuPrimary-parent:hover {
            color: var(--purple);
          }
        }

        @media ${mq.extralargeUp} {
          .MenuPrimary-parent-active {
            color: var(--purple);
          }
        }

        .MenuPrimary-parent:hover {
          cursor: default;
        }

        .MenuPrimary-children {
          padding-right: 2rem;
          padding-left: 2rem;
        }

        @media ${mq.extralargeUp} {
          .MenuPrimary-children {
            display: none;
            position: absolute;
            top: 6rem;
            padding-top: 2rem;
            padding-right: 0;
            padding-left: 0;
          }
        }

        .MenuPrimary-children a {
          color: var(--white);
          line-height: 4rem;
        }

        .MenuPrimary-children a:hover {
          border-bottom: 1px solid var(--white);
        }

        .MenuPrimary-child-active > a,
        .MenuPrimary-grandchild-active > a {
          border-bottom: 1px solid var(--white);
        }

        @media ${mq.extralargeUp} {
          .MenuPrimary-family,
          .MenuPrimary-children > li {
            display: inline-block;
          }
        }

        .MenuPrimary-family-small-screen-only {
          padding-bottom: var(--spacing);
        }

        @media ${mq.extralargeUp} {
          .MenuPrimary-family-small-screen-only {
            display: none;
          }
        }

        // .MenuPrimary-family-small-screen-only a {
        //   text-decoration: underline;
        // }

        @media ${mq.extralargeUp} {
          // Arrow up
          .MenuPrimary-children:before {
            display: block;
            position: absolute;
            top: 1rem;
            left: 4rem;
            content: "";
            width: 0;
            height: 0;
            border-left: 0.5rem solid transparent;
            border-right: 0.5rem solid transparent;
            border-bottom: 1rem solid var(--purple);
          }
        }

        @media ${mq.extralargeUp} {
          .MenuPrimary-family:hover > .MenuPrimary-children {
            display: inherit;
          }

          .MenuPrimary-family:focus-within > .MenuPrimary-children {
            display: inherit;
          }
        }

        @media ${mq.extralargeUp} {
          .MenuPrimary-children > li {
            float: none;
            padding-right: 2rem;
            padding-left: 2rem;
            display: list-item;
            position: relative;
            background-color: var(--purple);
            white-space: nowrap;
          }

          .MenuPrimary-children > li:first-of-type {
            padding-top: 1rem;
          }

          .MenuPrimary-children > li:last-of-type {
            padding-bottom: 1rem;
          }
        }

        .MenuPrimary-children li.MenuPrimary-grandchild {
          padding-left: 2ch;
        }

        // grand children menu
        .MenuPrimary-grandChildren {
          padding: 0;
          margin: 0;
          list-style: none;
          position: relative;
        }
        .MenuPrimary-grandchild {
          padding: 0;
          margin: 0;
        }

        .overlay {
          width: ${open ? "20%" : "0%"};
          height: 100vh;
          position: absolute;
          right: 0;
          z-index: 11;

          transition: transform 0.5s ease-in-out;
          transform: translateX(${open ? "0%" : "100%"});
        }

        @media ${mq.extralargeUp} {
          .overlay {
            display: none;
          }
        }

        @media print {
          .menuButton,
          nav {
            display: none;
          }
        }
      `}</style>
    </>
  );
};

export default MenuPrimary;
