import { useRouter } from "next/router";
import Link from "next/link";

const LanguageSwitcher = ({localesUrls = null}) => {
  const { locale: currentLocale, locales, asPath } = useRouter();
  if (locales.length < 2) return null;

  const router = useRouter();
  const onSelectChange = (event) => {
    const selectedLocale = event.target.value;
    if (localesUrls && selectedLocale in localesUrls) {
      router
        .push(localesUrls[selectedLocale], undefined, {
          locale: selectedLocale,
          scroll: false,
        })
        .then(() => {
          // trigger a window resize
          window.dispatchEvent(new Event("resize"));
        });
    } else {
      router
        .push(asPath, asPath, {
          locale: selectedLocale,
          scroll: false,
        })
        .then(() => {
          // trigger a window resize
          window.dispatchEvent(new Event("resize"));
        });
    };
    }

  return (
    <div className="LanguageSwitcher">
      <select name="languages" id="language-select" onChange={onSelectChange}>
        <option key={currentLocale} value={currentLocale}>
          {currentLocale}
        </option>
        {locales.map((locale) => {
          if (currentLocale != locale) {
            return (
              <option key={locale} value={locale}>
                {locale}
              </option>
            );
          }
        })}
      </select>
      <style jsx>{`
        select {
          // A reset of styles, including removing the default dropdown arrow
          appearance: none;
          background-color: transparent;
          border: none;
          padding: 0 1.5rem 0 0;
          margin: 0;
          width: 100%;
          font-family: inherit;
          font-size: inherit;
          color: inherit;
          cursor: inherit;
          line-height: inherit;
          text-transform: uppercase;
        }

        .LanguageSwitcher {
          display: flex;
          align-items: center;
          padding-top: 1rem;
          padding-bottom: 1rem;
        }

        .LanguageSwitcher::after {
          justify-self: end;
          align-self: center;
          content: "";
          width: 0.6em;
          height: 0.3em;
          background-color: var(--black);
          clip-path: polygon(100% 0%, 0 0%, 50% 100%);
          transform: translateX(-1rem);
        }

        .LanguageSwitcher:hover::after {
          background-color: var(--purple);
        }

        .LanguageSwitcher:hover {
          cursor: pointer;
          color: var(--purple);
        }
      `}</style>
    </div>
  );
};

export default LanguageSwitcher;
