import React, { useEffect } from 'react'
import { useErrorHandler } from 'react-error-boundary';
import { usePlantsStore } from 'stores/plantsStore';

export const LoadPlantsDb = () => {
  const handleError = useErrorHandler();
  const fetch = usePlantsStore((store) => store.fetch);
  useEffect(() => {
    fetch().catch(handleError);
  }, []);
  return null;
}

